import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import CryptoJS from './tools/CryptoJS';
import DateTool from './tools/DateTool';
import UrlTool from "@/tools/UrlTool";
export default {
  setup: function setup() {
    var href = window.location.href;
    var boo = false;

    if (href.indexOf("?") > -1) {
      var _i = href.indexOf("?");

      if (href.substring(_i - 2, _i) === "#/") {
        boo = true;
      }
    } else {
      var _len = href.length;

      if (href.substring(_len - 2, _len) === "#/") {
        boo = true;
      }
    }

    var activeMenuId = -1;

    if (!boo) {
      var index = UrlTool.getQueryVariable("_m");

      if (!isNaN(index)) {
        activeMenuId = parseInt(index);
      }
    }

    return {
      isHome: boo,
      activeMenuId: activeMenuId
    };
  },
  data: function data() {
    var lastActiveMenuIndex = 0;
    var menus = [{
      id: 1,
      title: "首页",
      name: 'Index',
      url: '/',
      class: 'item item-hover'
    }, {
      id: 2,
      title: "心情日记",
      name: 'Items',
      url: '/items',
      class: 'item item-hover'
    }, {
      id: 3,
      title: "知识笔记",
      name: 'Items',
      url: '/items',
      class: 'item item-hover'
    }, {
      id: 9,
      title: "个人档",
      name: 'Info',
      url: '/info',
      class: 'item item-hover'
    }];

    for (var i = 0; i < menus.length; i++) {
      var m = menus[i];

      if (this.activeMenuId === m.id) {
        m.class = 'item item-hover item-active';
        lastActiveMenuIndex = i;
      }
    }

    return {
      dmzLoading: true,
      screenWidth: window.innerWidth,
      nickName: '小小多',
      sign: '每个人都是一座孤岛',
      menuOpen: false,
      //大屏下横向菜单是否打开
      sideMenuOpen: false,
      //移动端侧边菜单是否打开
      menuColor: '#ffffff',
      bigPictureUrl: '/FileService/images/blog-vue/max-bg-ys.jpg',
      avatarUrl: '/FileService/images/a1/avatar-01.jpg',
      showBigPicture: this.isHome,
      bigPictureMaskShow: this.isHome,
      bigTextClass: 'big-text',
      smallTextClass: 'small-text',
      //最后一次选中的“menuList”中的菜单下标
      lastActiveMenuIndex: lastActiveMenuIndex,
      menuList: menus,
      sideDrawerMaskShow: false,
      expansionManifesto: '一个人的时候，无所谓孤独，细思下来，那是上帝赐予的一个人的安静时光。享受这份清静，体味这一个人的好天气，原来这寂寞也是能打动人的。人，生来就是一座孤岛，经历多了，这条道理才体味地越发深刻。如今不在犹豫，也不在徘徊，拿出直面真相的勇气，奋力经营人生这座孤岛，终于下定决心，过好这一生。',
      showExpansionManifesto: false,
      isDisabledScroll: this.isHome //是否禁用滚动条

    };
  },
  methods: {
    disabledScroll: function disabledScroll() {
      //禁用滚动
      var that = this;
      that.isDisabledScroll = true;
      document.body.addEventListener("touchmove", that.defferScroll, {
        passive: false
      });
      document.body.addEventListener("wheel", that.defferScroll, {
        passive: false
      });
    },
    enableScroll: function enableScroll() {
      //启用滚动
      var that = this;
      that.isDisabledScroll = false;
      document.body.removeEventListener("touchmove", that.defferScroll, {
        passive: false
      });
      document.body.removeEventListener("wheel", that.defferScroll, {
        passive: false
      });
    },
    //添加beforeunload监听事件
    createBeforeunloadHandler: function createBeforeunloadHandler() {
      var that = this;
      window.addEventListener('beforeunload', function () {
        if (that.lastActiveMenuIndex === 0) {
          that.bigPictureMaskShow = true;
          that.showBigPicture = true;
          window.scrollTo(0, 0);
        }
      });
    },
    closeBigPicture: function closeBigPicture() {
      var that = this;
      var h = window.innerHeight;
      var topH = document.getElementById("app").scrollTop;
      var time = 600;

      if (topH > 0) {
        time = time / h * (h - topH);
      }

      $("#app").animate({
        scrollTop: h
      }, Math.ceil(time), function () {
        that.showBigPicture = false;
        document.getElementById('app').scroll(0, 0);
        var $menuContainer = $('#menu_container');
        $menuContainer.animate({
          opacity: 1
        }, 500);

        if (that.isDisabledScroll) {
          that.enableScroll();
        }
      });
    },
    homeClick: function homeClick() {
      window.location.replace("/"); //this.$router.push({path:'/'});
      //this.showBigPicture = true;
      //window.scrollTo(0,0);
      //this.menuList[this.lastActiveMenuIndex].class = 'item item-hover';
    },
    menuClick: function menuClick(parm, index, type) {
      if (type === 'side') {
        this.closeSideDrawer();
      }

      if (parm.id === 61) {
        window.open("https://dumengzhao.cn/my_browser_home.html");
        return;
      } //params传参刷新后参数会丢失
      //this.$router.push({name: parm.name, params: {id: parm.id}});


      this.$router.push({
        name: parm.name,
        query: {
          key: CryptoJS.encryptByDES(JSON.stringify({
            id: parm.id
          })),
          _t: DateTool.getNowTime(),
          _m: parm.id
        }
      }); //切换菜单选中样式

      if (this.lastActiveMenuIndex !== index) {
        this.menuList[index].class = 'item item-hover item-active';

        if (this.lastActiveMenuIndex !== -1) {
          this.menuList[this.lastActiveMenuIndex].class = 'item item-hover';
        }
      }

      this.lastActiveMenuIndex = index;
    },
    affixChange: function affixChange(value) {
      if (value && this.showBigPicture) {
        this.showBigPicture = false;
        window.scrollTo(0, 0);

        if (this.isDisabledScroll) {
          this.enableScroll();
        }
      }
    },
    bigPictureLoad: function bigPictureLoad() {
      this.bigTextClass = 'big-text animation';
      this.smallTextClass = 'small-text animation';
      this.dmzLoading = false;
      this.bigPictureMaskShow = false;
    },
    openOrCloseMenu: function openOrCloseMenu() {
      //let that = this;
      var width = $('#menu_items').width();
      width = Math.ceil(width);
      var minWidth = width + Math.ceil($('#author_nav').width()) + Math.ceil($('#switch_nav').width()) + 20;
      var maxMenuContainerWidth = Math.ceil($('#menu_container').width()); //如果菜单展开的长度超出页面宽度，以右侧抽屉的形式打开菜单，“minWidth + 10”适当增加菜单实际展开宽度避免边距占位积压

      if (minWidth + 10 > maxMenuContainerWidth) {
        this.openSideDrawer();
      } else {
        if (this.menuOpen) {
          this.closeMenu();
        } else {
          this.menuOpen = true;
          $('#drawer_container').animate({
            width: width + 'px'
          }, 300);
        }
      }
    },
    closeMenu: function closeMenu() {
      this.menuOpen = false;
      $('#drawer_container').animate({
        width: '1px'
      }, 300);
    },
    openSideDrawer: function openSideDrawer(isSem) {
      var that = this;

      if (this.sideMenuOpen) {
        this.closeSideDrawer();
        return;
      }

      this.disabledScroll();

      if (isSem) {
        that.showExpansionManifesto = true;
      }

      that.sideMenuOpen = true;
      var sideDrawerDom = $('#side_drawer');
      sideDrawerDom.show();
      sideDrawerDom.animate({
        right: '0'
      }, 500, function () {
        that.sideDrawerMaskShow = true;
      });
    },
    closeSideDrawer: function closeSideDrawer() {
      if (!this.showBigPicture) {
        this.enableScroll();
      }

      if (this.showExpansionManifesto) {
        this.showExpansionManifesto = false;
      }

      this.sideMenuOpen = false;
      this.sideDrawerMaskShow = false;
      var sideDrawerDom = $('#side_drawer');
      sideDrawerDom.animate({
        right: '-100%'
      }, 300, function () {
        sideDrawerDom.hide();
      });
    },
    adaptiveLayout: function adaptiveLayout() {
      window.screenWidth = window.innerWidth;
      this.screenWidth = window.screenWidth; //改变窗口宽度时关闭打开的菜单

      if (this.menuOpen) {
        this.closeMenu();
      }

      if (this.sideMenuOpen) {
        this.closeSideDrawer();
      }
    },
    defferScroll: function defferScroll(event) {
      event.preventDefault();
    }
  },
  mounted: function mounted() {
    var that = this;

    if (this.isHome) {
      this.disabledScroll();
    } else {
      var $menuContainer = $('#menu_container');
      $menuContainer.animate({
        opacity: 1
      }, 500);
    }

    this.createBeforeunloadHandler();
    window.addEventListener("resize", function () {
      return function () {
        that.adaptiveLayout();
      }();
    });
  }
};