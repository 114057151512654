const urlTool = {
    //追加时间戳
    additionTime(url){
        return url.indexOf("?") > -1 ? url + "&__t=" + new Date().getTime() : url + "?__t=" + new Date().getTime();
    },
    encode(val){
        return encodeURIComponent(val).toString();
    },
    decode(val){
        return decodeURIComponent(val).toString();
    },
    getQueryVariable(name) {
        let search = window.location.search.substring(1)
        if (!search) {
            search = window.location.hash.split('?')[1]
        }
        if (search) {
            let obj = JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            return name ? obj[name] : obj
        }
        return null;
    }
}
export default urlTool
