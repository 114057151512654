import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import ElementPlus from 'element-plus'
//import 'element-plus/dist/index.css'
import VueWechatTitle from 'vue-wechat-title'
import { fetch, post, patch, put } from './tools/fetch'

const app = createApp(App)

app.use(router)
//app.use(ElementPlus)
app.use(VueWechatTitle)

app.config.globalProperties.$SystemName = '仙佛'
app.config.globalProperties.get = fetch
app.config.globalProperties.post = post
app.config.globalProperties.patch = patch
app.config.globalProperties.put = put
app.mount('#app')
