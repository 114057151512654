import CryptoJS from 'crypto-js'
const key = CryptoJS.enc.Utf8.parse("0123456789ABCDEF");  //十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('ABCDEF0123456789');   //十六位十六进制数作为密钥偏移量
const cryptoJS = {
    encryptByDES(text, isUpperCase){
        let srcs = CryptoJS.enc.Utf8.parse(text);
        let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        return isUpperCase ? encrypted.ciphertext.toString().toUpperCase() : encrypted.ciphertext.toString();
    },
    decryptByDES(text){
        let encryptedHexStr = CryptoJS.enc.Hex.parse(text);
        let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
        let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedStr.toString();
    }
}
export default cryptoJS