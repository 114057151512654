const dateTool = {
    //返回当前时间戳
    getNowTime(){
        return new Date().getTime()
    },
    formatTimestamp(time, hms){
        if(time.length===10 || time.length===13){
            let date = new Date(Number(time));
            let y = date.getFullYear().toString();
            let m = (date.getMonth() + 1).toString();
            let d = date.getDate().toString();
            let H = date.getHours().toString();
            let M = date.getMinutes().toString();
            let S = date.getSeconds().toString();
            if(hms){
                return y.padStart(2, '0')+'年'+m.padStart(2, '0')+'月'+d.padStart(2, '0')+'日 '+H.padStart(2, '0')+':'+M.padStart(2, '0')+':'+S.padStart(2, '0');
            }else {
                return y.padStart(2, '0')+'年'+m.padStart(2, '0')+'月'+d.padStart(2, '0')+'日';
            }
        }else {
            return "XXXX年XX月XX日 00:00:00";
        }
    }
}
export default dateTool