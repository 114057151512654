import { createRouter, createWebHashHistory } from "vue-router";
const title = "心无所恃，随遇而安";
const routes = [
            {
                path:"/",
                name:"Index",
                component:()=>import("./view/Index.vue"),
                meta: { title: title }
            },
            {
                path:"/items",
                name:"Items",
                component:()=>import("./view/Items.vue"),
                meta: { title: title }
            },
            {
                path:"/detail",
                name:"Detail",
                component:()=>import("./view/Detail.vue"),
                meta: { title: title }
            },
            {
                path:"/info",
                name:"Info",
                component:()=>import("./view/Info.vue"),
                meta: { title: title }
            }
        ]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
    scrollBehavior(){
      document.getElementById('app').scroll(0,0);
      //官方文档 return {top: 0} 将滚动条回归顶部的方法无效，可能是当前在app节点内发生的滚动，使用上面的自定义方法
      return {top: 0}
    }
});
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title || title
    }
    next()
});
export default router;
//在main.js中进行引入，并进行挂载注册到全局上
